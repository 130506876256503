
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
h1{
  color: #172554 !important;
}

/* Add this CSS to your global stylesheet or use Tailwind's custom CSS capabilities */
 

.scroll-container {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  justify-content: center;
  align-items: center;
}

.scroll-content {
  display: flex;
  animation: scroll-left 20s linear infinite;
}
